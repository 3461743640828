
(()=>{
	let Action = {}

	Action.checkout = function(url){
		Turbolinks.visit(url);
    }

    
	ui.exports('Tenant', Action)
})();