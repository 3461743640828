import Sortable from 'sortablejs'
document.addEventListener('turbolinks:load', () => {
    
    // sort menu and menu item
    const menu_id = location.pathname.split('/').pop();
    if ($('#menus').length > 0) {
        Sortable.create(document.getElementById('menus'), {
            delayOnTouchOnly: true, // only delay if user is using touch
            delay: 500,
            onEnd: (evt) => {
                $.ajax({
                    url: $('#menus').data("url"),
                    type: "PATCH",
                    data: {
                        menu: Sortable.create(document.getElementById('menus')).toArray(),
                        id: menu_id
                    }
                });
            }
        });
    }

    if($('#menu_items').length > 0) {

        Sortable.create(document.getElementById('menu_items'), {
            delayOnTouchOnly: true, // only delay if user is using touch
            delay: 500,
            onEnd: (evt) => {
                $.ajax({
                    url: $('#menu_items').data("url"),
                    type: "PATCH",
                    data: {
                        menu_item: Sortable.create(document.getElementById('menu_items')).toArray(),
                        id: menu_id
                    }
                });
            }
        });
    }

})