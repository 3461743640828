
(()=>{
  class AmountInput {
    constructor(el){
      this.el = el
      this.data = {}
    }

    init(el){
      let str = this.el.attr('ui')
      let className = str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`).substring(1)
      this.el.removeAttr('ui')
      this.el.addClass(className)
      
      let a = this.el.attr('ui-onchange').split('.');
      this.onchange = ui[a[1]][a[2]];
      this.el.removeAttr('ui-onchange')

      var _this = this
      this.el.find('[ui-bind]').each((i, el)=>{
        let $el = $(el)
        let bind_name = $el.attr('ui-bind')
        _this.data[`element_${bind_name}`] = $el

        Object.defineProperty(_this.data, bind_name, {
          get: function() { return this[`binded_${bind_name}`] },
          set: function(value) { 
            this[`binded_${bind_name}`] = value 
            this[`element_${bind_name}`].text(value)
          }
       });
      })

      Object.keys(el.data()).forEach((i)=>{

        this.data[i] = el.data(i)
      })
    }
  
    mount(){
      this.el.find('.left').on('click', ()=>{ this.decreaseAmount() })
      this.el.find('.right').on('click', ()=>{ this.increaseAmount() })
    }
  
    increaseAmount(){
        if(!window.touchmoved){
          this.data.amount = 10
          this.onchange(this.data.amount);
  
          window.touchmoved = false;
        }
      }
      
    decreaseAmount(){
      if(!window.touchmoved){
        this.data.amount = this.data.amount && this.data.amount > 0 ? --this.data.amount : 0;
        this.onchange(this.data.amount);

        window.touchmoved = false;
      }
    }
    
  }
	ui.exports('AmountInput', AmountInput)
})()
