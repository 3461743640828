

(()=>{
    let Action = {}
    
    const readURL = function(input, output) {
        if (input.files && input.files[0]) {
          var reader = new FileReader();
      
          reader.onload = function(e) {
            output.css("background-image", `url('${e.target.result}')`)
            output.removeClass('no-image')
          }
      
          reader.readAsDataURL(input.files[0]); // convert to base64 string
        }
    }
    
    preventSubmit = false
	Action.preventDefault = function(e) {
		var keyCode = e.keyCode || e.which;
        if (keyCode === 13) { 
            e.preventDefault();
            return false;
        }
    }
    
    Action.submit = function(el, options, callback) {
      if(!window.touchmoved){
        if (!options) options={}
        if (options.ajax == undefined) options.ajax = true;
        
        if (!preventSubmit) {
            var form = $(el).closest('form');

            // validate required fields
            var required_fields = form.find('input, textarea, select').filter('[required]');
            required_fields.each(function(key, el){
              $(el).on('keyup', function(){
                $(el).removeClass('error-border')
              });
            });

            var valid = true;
            required_fields.each(function(key, el){
              if(!$(el).val()){
                $(el).addClass('error-border')
                valid = false
              }else{
                $(el).removeClass('error-border')
              }
            });

            // submit data if all field valid
            if(valid) {
              preventSubmit = true;
              var data = form.serializeArray().reduce(function(obj, item) {
                obj[item.name] = item.value;
                return obj;
              }, {});

              if(options.ajax){
                $.ajax({
                    url: form[0].action,
                    method: data._method || 'post',
                    data: new FormData(form[0]),
                    headers: {
                      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    processData: false,
                    contentType: false,
                    beforeSend: () => {
                      $('.overlay').css('display','flex')
                    },
                    complete: () =>{
                      setTimeout(function(){
                        $('.overlay').css('display','none')
                      }, 2000)
                    },
                    error: (data) => {
                      preventSubmit = false;
                      if(callback) callback(data, null)
                    },
                    success: (data) => {
                      preventSubmit = false;
                      if(callback) callback(null, data)
                    }
                  });
              }else{
                form.submit();
              }
              
            }
        }

        window.touchmoved = false;
      }
    }

    Action.link = function(url, method){
      if(!window.touchmoved){
        if(event) event.preventDefault();
        method = method || 'GET';
        $.ajax({
            url: url,
            method: method,
            beforeSend: () => {
                $('.overlay').css('display','flex')
            },
            complete: () =>{
                $('.overlay').css('display','none')
            },
            success: (data) => {}
        });

        window.touchmoved = false;
      }
	}

    Action.triggerUploader = function(el, selector) {
      if(!window.touchmoved){
        event.preventDefault();
        var form = $(el).closest('form');
        form.find(selector).trigger('click');
        form.find(selector).trigger('ontouchend');

        window.touchmoved = false;
      }
    }

    Action.previewImage = function(el) {
      var image_div = $(el).closest('form').find('.ui-image')[0]
      readURL(el, $(image_div));
    }

	ui.exports('SimpleForm', Action)
})();