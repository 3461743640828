
(()=>{
	let Action = {}

    Action.changeStatus = function(el, status, text, url_load){
        if(!window.touchmoved){
            $(el).parent().parent().find('a').removeClass('selected');
            $(el).addClass('selected');
            $(el).parents('.page_order_detail').find('.tracker-header .text-status')
                .removeClass('received-color preparing-color delivery-color complete-color')
                .addClass(status+'-color')
                .text(text);

            // re-render message
            ui.DirectMessage.refresh($('#message-box')[0], url_load, function(){
            window.scrollTo(0,document.body.scrollHeight);
            });
            
            window.touchmoved = false;
        }
    }

	ui.exports('TrackerStatus', Action)
})();