
(()=>{
	_this = this;
	let Action = {}
	
	Action.tick = function(el, id, handler, value) {

		if(!window.touchmoved){
			let img = $(el).find('> img')

			if(value===undefined){
				img.toggleClass('show');
			} else {
				if(value) img.addClass('show');
				else img.removeClass('show');
			}
			

			if(handler) handler(id, !!img.hasClass('show'));


			window.touchmoved = false;
		}
	}

	ui.exports('CheckboxInput', Action)
})()
