import Sortable from 'sortablejs'
document.addEventListener('turbolinks:load', () => {

    // sort option and option item
    const menu_id = location.pathname.split('/').pop();
    if ($('#options').length > 0) {
        Sortable.create(document.getElementById('options'), {
            delayOnTouchOnly: true, // only delay if user is using touch
            delay: 500,
            handle: '.handle',
            onEnd: (evt) => {
                $.ajax({
                    url: $('#options').data("url"),
                    type: "PATCH",
                    data: {
                        option: Sortable.create(document.getElementById('options')).toArray(),
                        id: menu_id
                    }
                });
            }
        });
    }

    if($('.option_items').length > 0) {
        $('.option_items').each(function(){
            Sortable.create($(this)[0], {
                delayOnTouchOnly: true, // only delay if user is using touch
                delay: 500,
                onEnd: (evt) => {
                    $.ajax({
                        url: $(this).data("url"),
                        type: "PATCH",
                        data: {
                            option_item: Sortable.create($(this)[0]).toArray(),
                            id: menu_id
                        }
                    });
                }
            });
        })
    }


})