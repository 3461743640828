
(()=>{
	let Action = {}

	Action.options = {}
	Action.onOptionMenuChange = function(id, tick) {
		Action.options[id] = tick;
	}

	Action.clearOptions = function (){
		Action.options = {}
	}

	Action.tick = function (el, id, single_option){

		if(!window.touchmoved){
			event.preventDefault()

			if(single_option){
				$(el).parents('.menu-option-set').find('.menu-option-item').each(function(){
					if($(this).data('id')+"" !== id){
						ui.CheckboxInput.tick($(this).find('.checkbox-input')[0], $(this).data('id'), ui.MenuOptionItem.onOptionMenuChange, false)
					}
				})
			}
			
			ui.CheckboxInput.tick($(el).parent('.menu-option-item').find('.checkbox-input')[0], id, ui.MenuOptionItem.onOptionMenuChange)
		
			window.touchmoved = false;
		}
	}

	ui.exports('MenuOptionItem', Action)
})()
