/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import 'webpack-jquery-ui'
import 'webpack-jquery-ui/css'
import './touchpunch'

Rails.start();
Turbolinks.start()

import '../stylesheets/application.scss'
import './bootstrap_custom'
import './autosize'
import './modernizr'
import '@client-side-validations/simple-form/dist/simple-form.bootstrap4'

// Import All image
require.context('../images', true)

// Components
import './components/sortable-menu'
import './components/sortable-contact'
import './components/sortable-option'
import './components/flash_message'
import './components/autosize'

// ui
import './ui'
import  '../../views/ui/atoms/CheckboxInput'
import  '../../views/ui/atoms/AmountInput'
import  '../../views/ui/atoms/SimpleForm'

// modal
import '../../views/ui/modals/skins/CurveModal'
import '../../views/ui/modals/skins/SimpleModal'

//molecules
import  '../../views/ui/molecules/DirectMessage'
import  '../../views/ui/molecules/TrackerStatus'
import  '../../views/ui/molecules/MenuItemClassic'
import  '../../views/ui/molecules/MenuItemModern'
import  '../../views/ui/molecules/MenuItemTop'
import  '../../views/ui/molecules/MenuOptionItem'
import  '../../views/ui/molecules/SettingUserInput'
import '../../views/ui/molecules/TableSet'

// ui page
import  '../../views/ui/organisms/Hamberger'
import  '../../views/ui/organisms/MenuDetail'
import  '../../views/ui/organisms/OrderDetail'
import  '../../views/ui/organisms/Tenant'
import  '../../views/ui/organisms/Checkout'
import  '../../views/ui/organisms/Contact'
import  '../../views/ui/organisms/Intro'
import  '../../views/ui/organisms/Tracker'
import  '../../views/ui/organisms/LatestOrders'
import  '../../views/ui/organisms/EditMenu'
import  '../../views/ui/organisms/ListTenant'


document.addEventListener("contextmenu", function (e) {
    _this = $(e.target);
    var __REACT_DEVTOOLS_GLOBAL_HOOK__ = window.__REACT_DEVTOOLS_GLOBAL_HOOK__ || null;
    if(!__REACT_DEVTOOLS_GLOBAL_HOOK__){
    if(!['TEXTAREA', 'INPUT'].includes(_this.prop('tagName')) || _this.hasClass('allow-contextmenu') ){
        e.preventDefault();
    }}
    
}, false);

// turbolink overlay load page
document.addEventListener('turbolinks:before-visit', () => {
    $('.overlay').css('display','flex')

})

document.addEventListener('turbolinks:render', () => {
    $('.overlay').css('display','none')
    clearInterval(window.dm_interval)
    clearInterval(window.main_interval)
    clearInterval(window.second_interval)
})

$(window).on('touchmove', function(e){
    window.touchmoved = true;
}).on('touchstart', function(){
    window.touchmoved = false;
});