
(()=>{
	let Action = {}

	Action.checkout = function(url){
        
	}
	
	Action.submit = function(el, tenant_id) {
		var data = JSON.parse(localStorage.getItem(tenant_id) || '{}');

		var $form = $(el).closest('form');
		var customer_fields = $form.find('input, textarea, select');
		customer_fields.each(function(key, el){
			if($(el).val()){
				data[el.id] = $(el).val()
			}
		});

		localStorage.setItem(tenant_id, JSON.stringify(data));
		ui.SimpleForm.submit(el, null, (err, res) => {
			if (!err){
				Turbolinks.visit(res.redirect_to, { action: "replace" });
			}
			
		});
	}
    
	ui.exports('Checkout', Action)
})();