
(()=>{
	let Action = {}

	Action.close = function(){
		if(!window.touchmoved) {
			$("#modal_wrapper > .modal").modal('hide');
			
			window.touchmoved = false
		}
	}

	Action.save = function(el){
		if(!window.touchmoved) {
			event.preventDefault();
			$("#modal_wrapper > .modal .ui-btn").trigger('click');
			$("#modal_wrapper > .modal .ui-btn").trigger('ontouchend');
		
			window.touchmoved = false;
		}
    }

	ui.exports('CurveModal', Action)
})();