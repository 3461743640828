
(()=>{
	let Action = {}

	Action.close = function(){
		if(!window.touchmoved) {
			$("#modal_wrapper > .modal").modal('hide');

			window.touchmoved = false;
		}
	}

	ui.exports('SimpleModal', Action)
})();


