(()=>{
  Action = {}
  Action.deleteHandler = function(action, method){
    $.ajax({
      url: action,
      method: 'DELETE',
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      beforeSend: () => {
        $('.overlay').css('display','flex')
      },
      complete: () =>{
        $('.overlay').css('display','none')
      },
      success: (data) => {
        $('.tables').html(data.html)
      }
    });
  }

  Action.submit = function(action, method){
    let name = $('#table_name').val()
    let description = $('#table_description').val()
    
    $.ajax({
      url: action,
      method: method,
      data: {
        table: {
          name,
          description
        }
      },
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      beforeSend: () => {
        $('.overlay').css('display','flex')
      },
      complete: () =>{
        $('.overlay').css('display','none')
      },
      success: (data) => {
        $('.modal').modal('hide');
        $('.tables').html(data.html)
      }
    });
  }

  ui.exports('Table', Action)
})()
