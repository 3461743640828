
(()=>{
	let Action = {}
	let _this = this;
	_this.amount = null;

	Action.updateHandler = function(action, uuid, note, amount, referrer){
		let data = {
			id: uuid,
			note: note,
			options: ui.MenuOptionItem.options,
			referrer: referrer
		}

		if(_this.amount !== null) {
			data.amount = _this.amount;
		}else{
			data.amount = amount;
		}
		
		$.ajax({
				url: action,
				method: 'PUT',
				data: data,
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
				},
				beforeSend: () => {
					$('.overlay').css('display','flex')
				},
				complete: () =>{
					$('.overlay').css('display','none')
				},
				success: (data) => {
					$("#modal_wrapper > .modal").modal('hide');

					if(referrer == 'tenant') {
						$('.menu-sheet').html(data.html);
					} else if(referrer == 'checkout') {
						$('.checkout-page-reloader').html(data.html);
					}
					
					ui.MenuOptionItem.clearOptions();
					_this.amount = null;
				}
		});
	}  

	Action.onAmountChange = function(amount){
		_this.amount = amount
	}

	Action.refreshCart = function(action){
		$.ajax({
			url: action,
			method: 'GET',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			beforeSend: () => {
				$('.overlay').css('display','flex')
			},
			complete: () =>{
				$('.overlay').css('display','none')
			},
			success: (data) => {
				$('.menu-sheet').html(data.html)
			}
		});
	}

	ui.exports('MenuDetail', Action)
})();