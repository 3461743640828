
(()=>{
	let Action = {}
	
	function refresh(el, url, callback) {
		$.ajax({
			url: url,
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		}).done(function(response){
			el.innerHTML = response.html;
			if(callback) callback();
		});
	}

	function submitMessage(url, message, callback){
		$.ajax({
			url: url,
			method: 'post',
			data: { message: message },
			headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		}).done(function(){
			if(callback) callback();
		});
	}

	Action.refresh = function(el, url){
		refresh(el, url);
	}

	Action.submitMessage = function(url, message, el, url_load){
		if(!window.touchmoved){
			message = $(message);
			let text = message.val();
			submitMessage(url, text, function(){
				message.removeClass('is-invalid');
				refresh(el, url_load, function(){
					clearInterval(hack_invalid);
					window.scrollTo(0,document.body.scrollHeight);
				});
			});
			hack_invalid = setInterval(function(){
				message.val('')
			}, 33	);
			
			window.touchmoved = false;
		}
	}

	Action.enterEvent = function(e, url, message, el, url_load){
		$(message).removeClass('is-invalid')
        if(e.which == 13) {
			e.preventDefault();
			Action.submitMessage(url, message, el, url_load);
		}
	}

	ui.exports('DirectMessage', Action)
})();