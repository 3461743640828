
(()=>{
	let Action = {}
	
	Action.refreshTrackerInfo = function(url){
		$.ajax({
			url: url,
			method: 'GET',
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			},
			success: (data) => {
				$('#tracker-info').html(data.html)
			}
		});
	}

	ui.exports('Tracker', Action)
})();