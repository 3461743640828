
(()=>{
	let Action = {}
	Action.open = function(){
		if(!window.touchmoved) {
			$('.hamberger').css('width', '100%');
			$('.hamberger-content').css('width', $(window).width() + 'px')

			window.touchmoved = false;
		}
	} 

	Action.close = function(){
		if(!window.touchmoved) {
			$('.hamberger').css('width', '0');
			
			window.touchmoved = false;
		}
	}

	ui.exports('Hamburger', Action)
})();
