import Sortable from 'sortablejs'
document.addEventListener('turbolinks:load', () => {

    // sort contact and contact item
    const contact_id = location.pathname.split('/').pop();
    if ($('#contacts').length > 0) {
        Sortable.create(document.getElementById('contacts'), {
            delayOnTouchOnly: true, // only delay if user is using touch
            delay: 500,
            onEnd: (evt) => {
                $.ajax({
                    url: $('#contacts').data("url"),
                    type: "PATCH",
                    data: {
                        contact: Sortable.create(document.getElementById('contacts')).toArray(),
                        id: contact_id
                    }
                });
            }
        });
    }

    if($('#contact_items').length > 0) {

        Sortable.create(document.getElementById('contact_items'), {
            delayOnTouchOnly: true, // only delay if user is using touch
            delay: 500,
            onEnd: (evt) => {
                $.ajax({
                    url: $('#contact_items').data("url"),
                    type: "PATCH",
                    data: {
                        contact_item: Sortable.create(document.getElementById('contact_items')).toArray(),
                        id: contact_id
                    }
                });
            }
        });
    }


})