
(()=>{
    let Action = {}
    
    Action.submit = function(el){
        var checkbox = ui.MenuOptionItem.options;
        var form = $(el).closest('form');

        var checkboxName = [
            'tenant_customer_input_name',
            'tenant_customer_input_telephone',
            'tenant_customer_input_additional_note',
            'tenant_customer_input_delivery_map',
            'tenant_customer_input_table'
        ];

        form.find('input[type="hidden"]').each((key, input)=>{
            if(checkboxName.includes(input.id) && checkbox[input.id] !== undefined) {
                input.value = checkbox[input.id] ? '1' : '0';
            }
        })

        ui.SimpleForm.submit(el, null, (err, data) => {});
    }

	ui.exports('SettingUserInput', Action)
})();