
(()=>{
	let Action = {}

	function reload(el, url, callback) {
		$.ajax({
			url: url,
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		}).done(function(response){
			el.innerHTML = response.html;
			if(callback) callback();
		});
	}

	Action.reload = function(el, url){
		reload(el, url);
	}

	ui.exports('LatestOrders', Action)
})();