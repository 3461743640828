
(()=>{
	let Action = {}
	Action.selectHandler = function(action, method, id){
		return function(){
			$.ajax({
				url: action,
				method: method,
				data: {
					id: id
				},
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
				},
				beforeSend: () => {
					$('.overlay').css('display','flex')
				},
				complete: () =>{
					$('.overlay').css('display','none')
				},
				success: (data) => {
					$('.menu-sheet').html(data.html)
				}
			});
		}
	}

	ui.exports('MenuItemModern', Action)
})()
