
window.ui = window.ui || {}

window.ui.exports = function(name, object){
    window.ui[name] = object;
    // Object.keys(object).forEach((name)=>{
    //     window.ui[name] = some_func(name)
    // })

    // function some_func(name){
    //     return function (_this){
    //         let ui_elem = $(_this).parents('[ui]')
    //         let ui = $(_this).parents('[ui]').attr('ui')
    //         let args = Array.prototype.slice.call(arguments, 1)
    //         window.ui[ui][name].apply(this, [ui_elem].concat(args));
    //     }
    // }
}

window.ui.init = function(){
    $("[ui]").each((key, _this)=>{
        let el = $(_this)
        let ui_name = el.attr('ui')
        if(typeof(window.ui[ui_name])=='object'){
            if(window.ui[ui_name].init) window.ui[ui_name].init(el)
            if(window.ui[ui_name].mount) window.ui[ui_name].mount(el)
        }else{
            let ui = new window.ui[ui_name](el)
            if(ui.init) ui.init(el)
            if(ui.mount) ui.mount(el)
        }
        
    })
}

$(document).on('turbolinks:load', () => {
    window.ui.init()
})